<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Salas</h4>
            <button type="button" class="close" data-dismiss="modal" @click="$emit('closeSalas')">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col">
                <router-link :to="{ name: 'LocaisTreinamentosSalasCadastro', query: { idLocal: local.id } }">
                  <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <MyTable
                  :data="local.salas"
                  :columns="columns"
                  :itemsPerPage="10">
                  <template #column="{item, column}">
                    <span v-if="column.field == 'actions'">
                      <i class="fas fa-edit mr-3 btn btn-warning" title="Editar sala" @click="editarItem(item.id)"></i>
                      <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar sala" @click="deletarItem(item)"></i>
                    </span>
                    <span v-else>
                      {{item[column.field]}}
                    </span>
                  </template>
                </MyTable>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
         
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MyTable from './../../../../uicomponents/MyTable'
import locaisTreinamentosRepository from './../../../../services/api/locaisTreinamentosRepository'
export default {
  components: {
    MyTable
  },
  props: ['propLocal'],
  created () {
    this.local = this.propLocal
  },
  data () {
    return {
      local: {},
      columns: [
        {
          label: 'Sala',
          field: 'sala',
          sortable: 'sala'
        },
        {
          label: 'Descrição',
          field: 'descricao',
          sortable: 'descricao'
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ]
    }
  },
  methods: {
    editarItem (id) {
      this.$router.push({ name: 'LocaisTreinamentosSalasCadastro', query: { id: id } })
    },
    deletarItem (sala) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover sala',
        text: `Deseja deletar a sala ${sala.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarSala(sala)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Sala removida com sucesso!'
          }).then(() => {
            _this.fetch()
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarSala (sala) {
      this.isLoading = true
      locaisTreinamentosRepository.deletarLocalSala(sala.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    }
  }
}
</script>

<style>
  
</style>